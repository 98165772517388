<template>
  <div class="send-email-modal">
    <b-modal
      :id="'bv-send-email-modal:' + name"
      cancel-title="Non"
      @ok="onConfirm"
      @cancel="onCancel"
      :ok-disabled="!isValid"
      ok-variant="primary"
      ok-title="Envoyer"
    >
      <template v-slot:modal-title>
        {{ title }}
      </template>
      <b-form>
        <b-row>
          <b-col>
            <b-form-group
              id="fromEmail-group"
              label="Répondre à"
              label-for="fromEmail"
              description="Adresse pour la réponse des familles aux emails envoyés"
            >
              <b-form-input
                id="fromEmail"
                type="email"
                required
                v-model="fromEmail"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="subject-group"
              label="Sujet"
              label-for="subject"
              description="Sujet de l'email"
            >
              <b-form-input
                id="subject"
                type="text"
                required
                v-model="subject"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="body-group"
              label="Message"
              label-for="body"
              description="Ce message optionnel sera inclus dans chaque email envoyé"
            >
              <b-form-textarea
                id="body"
                v-model="body"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import store from '@/store'

export default {
  name: 'SendEmailModal',
  components: {
  },
  props: {
    name: String,
    title: String,
    initSubject: {
      type: String,
      defaultValue: 'Confirmation',
    },
    initFromEmail: {
      type: String,
      defaultValue: '',
    },
  },
  data() {
    return {
      fromEmail: '',
      subject: '',
      body: '',
    }
  },
  watch: {
    initSubject: function() {
      this.subject = this.initSubject
    },
    initFromEmail: function() {
      this.fromEmail = this.initFromEmail || this.userEmail
    },
    userEmail: function() {
      this.fromEmail = this.initFromEmail || this.userEmail
    },
  },
  created() {
    this.fromEmail = this.initFromEmail || this.userEmail
  },
  computed: {
    isValid() {
      return this.subject && this.fromEmail
    },
    userEmail() {
      return store.getters.userEmail
    },
  },
  methods: {
    onConfirm() {
      if (this.isValid) {
        this.$emit(
          'confirmed',
          {
            subject: this.subject,
            body: this.body,
            fromEmail: this.fromEmail,
          }
        )
      }
    },
    onCancel() {
      this.$emit('cancelled')
    },
  },
}
</script>
<style lang="less" scoped>
</style>
